@import "~modularscale-sass/stylesheets/modularscale";
@import "~swiper/swiper.scss";
@import "~swiper/components/pagination/pagination.scss";

@import "./fonts.scss";

$davys-grey: #505050;
$eerie-black: #232323;
$red-ryb: #FF2E2B;
$rosso-corsa: #CD0000;
$sky-blue-crayola: #4CD9ED;
$azure: #437FEC;
$light-steel-blue: #BECDE2;
$silver: #C4C4C4;

$screen-narrow: 1234px;
$screen-tablet: 985px;
$screen-mobile: 600px;

$elevation-shadow: 6px 6px 10px rgba(0, 0, 0, 0.1);

$transition-timing: cubic-bezier(0.155, 0.755, 0.03, 1);
$background-transition-duration: 0.1s;

@mixin bodyText1 {
  color: $davys-grey;
  font-family: "Muller", sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.625;
}

@mixin header5 {
  color: $eerie-black;
  font-family: "Muller", sans-serif;
  font-size: 40px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 1.06;

  @media screen and (min-width: $screen-mobile) {
    font-size: 60px;
  }
}

@mixin header6 {
  color: $davys-grey;
  font-family: "Muller", sans-serif;
  font-size: 32px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 1.06;

  @media screen and (min-width: $screen-mobile) {
    font-size: 45px;
  }
}

@mixin subtitle1 {
  color: $davys-grey;
  font-family: "Muller", sans-serif;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 1.2;
}

@mixin subtitle2 {
  color: $eerie-black;
  font-family: "Muller", sans-serif;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.6;
}

@mixin caption {
  color: $eerie-black;
  font-family: "Muller", sans-serif;
  font-size: 14px;
  font-weight: 200;
  letter-spacing: 0;
  line-height: 1.5;
}

@mixin primary-button {
  background: $red-ryb;
  background: linear-gradient(75deg, $rosso-corsa 0%, $red-ryb 100%);
  border-radius: 40px;
  color: white;
  font-family: "Muller", sans-serif;
  font-size: 20px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 1;
  padding: 18px 0;
  text-align: center;
  width: 178px;
}

@mixin flat-button {
  @include primary-button();

  background: $sky-blue-crayola;
  background: linear-gradient(75deg, $azure 0%,  $sky-blue-crayola 100%);
  padding: 2px;
  color: $sky-blue-crayola;

  .content {
    background: white;
    border-radius: 40px;
    display: block;
    padding: 16px 0;
    width: 100%;

    >font {
      background: $sky-blue-crayola;
      background: linear-gradient(75deg, $azure 0%,  $sky-blue-crayola 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}

@mixin swiper-pagination {
  bottom: 0;

  .swiper-pagination-bullet {
    background-color: transparent;
    height: 16px;
    margin: 0 13px;
    opacity: 1;
    position: relative;
    width: 16px;

    &::after {
      border-radius: 50%;
      border: 1px solid $silver;
      content: "";
      height: 150%;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 150%;
      z-index: 0;
    }
  }

  .swiper-pagination-bullet-active {
    background-color: $light-steel-blue;
  }
}

@mixin full-cover {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

@import "./default.scss";

header {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  left: 0;
  margin: auto;
  max-width: 1049px;
  overflow: auto;
  padding: 24px 32px;
  position: absolute;
  right: 0;
  top: 0;
  transition: background-color ease-out $background-transition-duration;
  width: 100%;
  z-index: 1;

  .logo {
    .image-link {
      display: none;

      >img {
        width: 227px;
      }

      @media screen and (min-width: $screen-mobile) {
        display: flex;
      }
    }
  }

  nav {
    display: none;
    align-items: center;

    a:not(:first-of-type) {
      margin: 0 0 0 40px;
    }
  }

  a {
    @include subtitle2();

    color: $eerie-black;
    font-weight: 500;
  }

  .hamburger-menu {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 32px;
    margin: 6px 0;
    position: relative;
    width: 32px;

    span,
    span::before,
    span::after {
      background-color: $eerie-black;
      display: block;
      height: 1px;
      position: absolute;
      transition: transform ease-out 0.2s;
      width: 100%;
    }

    span::before {
      content: "";
      top: -12px;
    }

    span::after {
      content: "";
      top: 12px;
    }
  }

  &:target {
    align-content: flex-start;
    background: white;
    flex-wrap: wrap;
    height: 100%;
    position: fixed;

    .hamburger-menu {
      display: flex;
    }

    .hamburger-menu > span {
      transform: rotate(45deg);
    }

    .hamburger-menu > span::before {
      top: 0;
      transform: rotate(0);
    }

    .hamburger-menu > span::after {
      top: 0;
      transform: rotate(90deg);
    }

    nav {
      display: flex;
      flex-basis: 100%;
      flex-direction: column;
      padding: 40px 0;
      animation: appear-bottom-up 1.2s $transition-timing both;

      a:not(:first-of-type) {
        margin: 40px 0 0 0;
      }
    }
  }

  @media screen and (min-width: $screen-mobile) {
    padding: 11px 32px;
  }

  @media screen and (min-width: $screen-tablet) {

    nav {
      display: flex;
    }

    .hamburger-menu {
      display: none;
    }
  }
}

.layout-constraints {
  box-sizing: border-box;
  margin: auto;
  max-width: 1234px;
  overflow: hidden;
  padding: 0 32px;
  width: 100%;
}

.main-section {
  display: flex;
  flex-direction: column;
  margin: 166px auto 0;
  max-width: 985px;

  .app-title-line {
    @include header5();

    text-align: center;
  }

  .conversion-container {
    display: flex;
    flex-direction: column;
    margin: 27px auto 0;
    max-width: 820px;
  }

  .promo-line {
    @include subtitle2();

    display: inline-flex;
    justify-content: center;
    text-align: center;
  }

  .conversion-controls {
    display: inline-flex;
    justify-content: center;
    flex-wrap: wrap;

    a {
      margin: 37px 10px 0;
    }

    .primary {
      @include primary-button;
    }

    .secondary {
      @include flat-button;
    }
  }

  @media screen and (min-width: $screen-mobile) {
    margin: 191px auto 0;
  }
}

.main-illustration {
  display: none;
  margin: 106px auto 0;
  max-width: 985px;
  width: 100%;

  @media screen and (min-width: $screen-tablet) {
    display: block;
  }
}

.details-section {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 146px;

  .spacer {
    min-width: 72px;
    min-height: 72px;
  }

  .text-block {
    box-sizing: border-box;
    max-width: 600px;

    h6 {
      @include header6();
    }

    p {
      margin-top: 20px;
    }

    @media screen and (min-width: $screen-tablet) {
      flex-basis: 53%;
    }
  }

  .media-block {
    display: flex;
    max-width: 600px;

    img {
      width: 100%;
    }

    @media screen and (min-width: $screen-tablet) {
      flex-basis: 47%;
    }
  }

  @media screen and (min-width: $screen-tablet) {
    flex-direction: row;
  }
}

.advantages-section {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 100px;

  figure {
    align-items: flex-start;
    display: flex;
    flex-basis: 100%;
    flex-direction: column;
    margin: 64px auto 0;
    max-width: 600px;
    position: relative;

    .illustration-block {
      height: 100px;
      align-self: center;

      img {
        height: 100%;
        width: auto;
      }
    }

    .text-block {
      margin-top: 26px;
      text-align: center;

      span {
        @include subtitle1();
      }

      p {
        @include bodyText1();

        margin-top: 17px;
      }
    }

    @media screen and (min-width: $screen-tablet) {
      flex-basis: 25%;
      padding: 0;
    }
  }
}

.our-clients-section {
  margin-top: 165px;

  h6 {
    @include header6();
    
    display: block;
    text-align: center;
  }

  .companies-list {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 74px;

    figure {
      align-items: flex-start;
      display: flex;
      flex-grow: 1;
      min-width: 240px;
      padding: 10px;
  
      .content-block {
        border: 1px solid $light-steel-blue;
        padding: 17px 0;
        width: 100%;
        border-radius: 20px;
        text-align: center;
      }
    }
  }

  .swiper-container {
    margin-top: 80px;
    padding-bottom: 82px;
    width: 100%;

    .swiper-wrapper {
      transition-timing-function: linear;
    }

    .swiper-pagination {
      @include swiper-pagination();
    }
    
    .swiper-slide {
      box-sizing: border-box;
      padding: 10px;
  
      .content-block {
        box-sizing: border-box;
        border: 1px solid $light-steel-blue;
        padding: 32px 15px;
        width: 100%;
        border-radius: 20px;
        text-align: center;
      }
    }
  }
}

.pricing-section {
  background: $sky-blue-crayola;
  background: linear-gradient(75deg, $azure 0%,  $sky-blue-crayola 100%);
  margin-top: 72px;
  padding: 83px 32px 210px;

  h6 {
    @include header6();
    
    color: white;
    display: block;
    text-align: center;
  }

  .promo-line {
    @include subtitle2();

    color: white;
    margin-top: 23px;
    text-align: center;
  }

  .progress-bar {
    display: flex;
    justify-content: center;
    margin-top: 35px;

    div {
      background-color: $light-steel-blue;
      border-radius: 3px;
      display: inline-flex;
      height: 6px;
      margin: 0 15px;
      width: 60px;

      &.active {
        background-color: white;
      }
    }
  }

  .question {
    @include subtitle1();

    color: white;
    margin-top: 53px;
    text-align: center;
  }

  .answers-block {
    display: flex;
    flex-direction: column;
    margin: 32px auto 0;
    max-width: 575px;
    width: 100%;

    .answer {
      background: transparent;
      border-radius: 20px;
      border: 1px solid white;
      box-sizing: border-box;
      color: white;
      font-family: 'Muller';
      font-size: 18px;
      font-weight: 700;
      line-height: 1;
      margin-top: 20px;
      padding: 19px 26px 18px;
      text-align: left;
      width: 100%;
    }
  }
}

.our-team-section {
  margin-top: 109px;

  h6 {
    @include header6();
    
    display: block;
    text-align: center;
  }

  .swiper-container {
    margin-top: 141px;
    padding-bottom: 82px;
    width: 100%;

    .swiper-wrapper {
      transition-timing-function: linear;
    }

    .swiper-pagination {
      @include swiper-pagination();
    }
    
    .swiper-slide {
      align-items: center;
      border-radius: 20px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      padding: 51px 0;
      text-align: center;

      img {
        height: 131px;
        border-radius: 66px;
      }

      .name,
      .role,
      .about {
        max-width: 300px;
      }

      .name {
        margin-top: 38px;
        font-weight: 700;
      }

      .role {
        @include caption();

        font-weight: 400;
      }

      .about {
        @include caption();

        margin-top: 30px;
      }
    }
  }
}

.contact-us-section {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 118px;
  justify-content: center;

  .text-block {
    box-sizing: border-box;
    max-width: 580px;
    text-align: center;
    margin-top: 57px;

    h6 {
      @include header6();
    }

    .primary {
      @include primary-button();

      margin-top: 57px;
      display: inline-block;
    }

    @media screen and (min-width: $screen-tablet) {
      margin-top: 0;
      text-align: left;
    }
  }

  .illustration-block {
    display: flex;
    max-width: 610px;

    img {
      width: 100%;
    }

    @media screen and (min-width: $screen-tablet) {
      margin-right: -100px;
    }
  }

  @media screen and (min-width: $screen-tablet) {
    flex-direction: row;
  }
}

footer {
  padding: 83px 0 110px;
  background-color: $davys-grey;
  margin-top: 134px;

  .layout-constraints {
    padding: 24px 32px;
    max-width: 985px;
    align-items: center;
    flex-direction: column;
    display: flex;
    justify-content: space-between;

    .contacts-block {
      display: flex;
      flex-direction: column;
      text-align: center;
  
      .logo {
        display: flex;
  
        >img {
          width: 227px;
        }
      }
  
      .telephone {
        font-weight: 700;
        font-size: 19px;
        color: white;
        margin-top: 32px;
      }
    }
  
    nav {
      display: flex;
      align-items: center;
      flex-direction: column;
  
      a {
        margin: 31px 0 0;
      }
  
      @media screen and (min-width: $screen-tablet) {
        flex-direction: row;
  
        a {
          margin: 31px 0 31px 40px;
        }
      }
    }
  
    a {
      @include subtitle2();
  
      color: white;
      font-weight: 500;
    }
  
    @media screen and (min-width: $screen-tablet) {
      flex-direction: row;
      align-items: flex-start;
    }
  }

  @media screen and (min-width: $screen-tablet) {
    margin-top: 0;
  }
}

.overlay {
  @include full-cover();

  align-items: center;
  display: flex;
  justify-content: center;
  position: fixed;
  transition: background-color ease-out $background-transition-duration;
  visibility: hidden;
  z-index: 2;

  &:target {
    background: transparentize($color: $davys-grey, $amount: 0.7);
    visibility: visible;

    .popup {
      display: flex;
    }
  }

  .card-layout {
    background-color: white;
    border-radius: 20px;
  }

  .dismissible {
    @include full-cover();
  }

  .popup {
    @include subtitle1();

    box-sizing: border-box;
    color: $davys-grey;
    display: none;
    flex-direction: column;
    line-height: 1.5;
    margin: 16px;
    max-height: 100%;
    max-width: 344px;
    overflow-y: auto;
    padding: 32px 24px;
    position: relative;
    text-align: center;
    z-index: 1;

    .close {
      font-weight: 300;
      position: absolute;
      right: 21px;
      top: 11px;
      font-size: 32px;
    }

    .caption {
      @include bodyText1();

      margin-top: 32px;
    }

    [alert] {
      @include caption();

      margin-top: 20px;
      color: $red-ryb;
      display: none;
    }

    [telephone] {
      @include subtitle1();
    }

    input {
      @include bodyText1();

      width: 100%;
      box-sizing: border-box;
      border-radius: 20px;
      padding: 10px 15px;
      border: 1px solid;
      border-color: $silver;
      margin-top: 21px;

      &:focus {
        border-color: $light-steel-blue;
      }

      &::placeholder {
        @include caption();

        color: $silver;
      }
    }

    .submit-btn {
      @include flat-button();

      margin-top: 41px;
    }

    .validation-error {
      .action-btn {
        margin-top: 22px;
      }

      [alert] {
        display: block;
      }

      input.error {
        border-color: $red-ryb;

        &::placeholder {
          color: $red-ryb;
        }
      }
    }

    @media screen and (min-width: $screen-tablet) {
      padding: 40px 32px;
      margin: 0;
    }
  }
}

