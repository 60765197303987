@font-face {
    font-family: 'Muller';
    src: url('../fonts/MullerRegular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: block;
}


@font-face {
    font-family: 'Muller';
    src: url('../fonts/MullerExtraBold.woff2') format('woff2');
    font-weight: 800;
    font-style: normal;
    font-display: block;
}


@font-face {
    font-family: 'Muller';
    src: url('../fonts/MullerBold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: block;
}



@font-face {
    font-family: 'Muller';
    src: url('../fonts/MullerMedium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: block;
}


@font-face {
    font-family: 'Muller';
    src: url('../fonts/MullerLight.woff2') format('woff2');
    font-weight: 200;
    font-style: normal;
    font-display: block;
}

